import lozad from "lozad";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
  document.body.addEventListener('mousemove', handleMouseMove);

  const burger = document.querySelector('.main-burger');
  const header = document.querySelector('.main-header');
  const cursor = document.querySelector('.cursor');
  const links = document.querySelectorAll('a, button');

  function handleDOMContentLoaded() {
    const observer = lozad('.lozad', {
      loaded: function(el) {
        el.classList.add('lozad--loaded');
      }
    });
    observer.observe();

    links.forEach((link) => {
      link.addEventListener('mouseenter', handleMouseEnter);
      link.addEventListener('mouseleave', handleMouseLeave);
    });

    burger.addEventListener('click', () => {
      if (burger.classList.contains('main-burger--active')) {
        burger.classList.remove('main-burger--active');
        header.classList.remove('main-header--with-navigation');
      } else {
        burger.classList.add('main-burger--active');
        header.classList.add('main-header--with-navigation');
      }
    });
  }

  function handleMouseMove(e) {
    cursor.style.left = `${e.clientX - 30}px`;
    cursor.style.top = `${e.clientY - 30}px`;
  }

  function handleMouseEnter() {
    cursor.classList.add('cursor--hover');
  }

  function handleMouseLeave() {
    cursor.classList.remove('cursor--hover');
  }


})();
